<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList" @clearSearch="clearSearch">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="关键字">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable placeholder="按手机号">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker class="from-item-width" size="small" unlink-panels v-model="searchFormData.day" type="date" value-format="yyyy-MM-dd" placeholder="按评论时间">
				</el-date-picker>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="txt" title="评价信息" align="center" />
			<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" />
			<vxe-table-column slot="table-item" field="score" title="评分" align="center" width="50px" />
			<vxe-table-column slot="table-item" field="order_no" title="关联订单" align="center" />
			<vxe-table-column slot="table-item" field="tm" title="评分时间" align="center" />
			<vxe-table-column slot="table-item" title="回复内容" align="center">
				<template v-slot="{row}">
					<span :style="{color:row.reply?'':'#BBB'}">{{ row.reply||'暂无' }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center">
				<template v-slot="{row}">
					<el-button v-if="!row.reply" size="small" @click="openReply(row)">回复</el-button>
				</template>
			</vxe-table-column>
		</Tables>				
		<!-- 评论回复 -->
		<el-dialog title="评论回复" :visible.sync="replyDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="replyDialogFormData" ref="replyForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="group_name">
					{{ replyDialogFormData.group_name }}
				</el-form-item>
				<el-form-item label="评论内容" prop="price">
					{{ replyDialogFormData.txt }}
				</el-form-item>				
				<el-form-item label="评分" prop="price">
					<el-rate v-model="replyDialogFormData.score" disabled></el-rate>
				</el-form-item>
				<el-form-item label="回复" prop="price">
					<el-input type="textarea" v-model="replyDialogFormData.reply"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveReply()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activitycommon-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '单场站评分',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				// 回复
				replyDialogShow: false,
				replyDialogFormData: {},
			}
		},
		computed: {
		},
		mounted() {
			
		},
		activated() {
			this.$refs.xTable.refreshTable();
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					group_id: this.$route.query.group_id,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.Group.scorelist(params);
				this.List = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 回复评论
			openReply(row) {
				this.replyDialogShow = true;
				this.replyDialogFormData = row;
			},
			// 关闭弹窗
			closeDialog() {
				this.replyDialogShow = false;
			},
			// 回复保存
			async saveReply(){
				let params = {
					token: this.$store.state.user.token,
					group_id: this.replyDialogFormData.group_id,
					order_id: this.replyDialogFormData.order_id,
					reply: this.replyDialogFormData.reply,
				}
				await this.$api.Market.Group.scorereply(params);
				this.$notify({
					title: '成功',
					message: '操作成功',
					type: 'success'
				})
				this.closeDialog();
				this.typeChange();
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
